.input-group {
  margin-bottom: 20px;

  & .error-message {
    display: none;
  }

  label {
    color: #333;
    font-size: 14px;
    margin: 0 0 7px;
  }
  
  textarea {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #e6e6e6;
    color: #333;
    font-size: 14px;
    height: 138px;
    padding: 17px 20px;
  }

  & input[type="file"] {
    padding-top: 6px !important;
  }
}

.error {
  & label {
    color: $error-color;
  }

  & input[type="text"] {
    border: 1px solid $error-color !important;
    outline: none;
  }

  & input[type="password"] {
    border: 1px solid $error-color !important;
    outline: none;
  }

  & textarea {
    border: 1px solid $error-color !important;
    outline: none;
  }

  & .error-message {
    display: inline-block;
    color: $error-color;
    display: inline-block;
    margin: 5px 0 0;
  }
}
