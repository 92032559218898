.order-history {
  .view-order-detail {
    width: 100px;
    a,
    button {
      color: #666;
      background: none;
      border: none;
      font-size: 17px;
      margin: 0 13px;
      &:hover {
        color: $theme-color;
      }
    }
  }
} 

.order-history-detail-back-btn-wrapper {
  button {
    color: #666;
    background: none;
    border: none;
    font-size: 17px;
    margin: 0px;
    &:hover {
      color: $theme-color;
    }
  }
}