/* Spacing */

.min-h-0 {
  min-height: 0px !important;
}

/* Spacing end */

/* Border radius */

.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-full {
  border-radius: 9999px;
}

/* Border radius end */

/* Font sizes */

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

/* Font sizes end */

/* Width */

.w-0 {
  width: 0px;
}
.w-0.5 {
  width: 0.125rem;
}
.w-1 {
  width: 0.25rem;
}
.w-1.5 {
  width: 0.375rem;
}
.w-2 {
  width: 0.5rem;
}
.w-2.5 {
  width: 0.625rem;
}
.w-3 {
  width: 0.75rem;
}
.w-3.5 {
  width: 0.875rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-9 {
  width: 2.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-11 {
  width: 2.75rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-28 {
  width: 7rem;
}
.w-32 {
  width: 8rem;
}
.w-36 {
  width: 9rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-52 {
  width: 13rem;
}
.w-56 {
  width: 14rem;
}
.w-60 {
  width: 15rem;
}
.w-64 {
  width: 16rem;
}
.w-72 {
  width: 18rem;
}
.w-80 {
  width: 20rem;
}
.w-96 {
  width: 24rem;
}
.w-97 {
  width: 25rem;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-40 {
  min-width: 10rem;
}
/* Width end */

/* Height */

.h-0 {
  height: 0px;
}
.h-0.5 {
  height: 0.125rem;
}
.h-1 {
  height: 0.25rem;
}
.h-1.5 {
  height: 0.375rem;
}
.h-2 {
  height: 0.5rem;
}
.h-2.5 {
  height: 0.625rem;
}
.h-3 {
  height: 0.75rem;
}
.h-3.5 {
  height: 0.875rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-28 {
  height: 7rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-52 {
  height: 13rem;
}
.h-56 {
  height: 14rem;
}
.h-60 {
  height: 15rem;
}
.h-64 {
  height: 16rem;
}
.h-72 {
  height: 18rem;
}
.h-80 {
  height: 20rem;
}
.h-96 {
  height: 24rem;
}
.h-auto {
  height: auto;
}
.h-px {
  height: 1px;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.h-screen-80 {
  height: 80vh;
}
.min-h-screen {
  height: 100vw;
}
/* Height end */

/* Padding */

.p-8 {
  padding: 2rem;
}
.p-9 {
  padding: 2.25rem;
}
.p-10 {
  padding: 2.5rem;
}

.px-8 {
  padding: 2rem;
}
.px-9 {
  padding: 2.25rem;
}
.px-10 {
  padding: 2.5rem;
}

.py-8 {
  padding: 2rem;
}
.py-9 {
  padding: 2.25rem;
}
.py-10 {
  padding: 2.5rem;
}

/* Padding end */

/* Top start */

.top-2pt5 {
  top: 0.625rem;
}

/* Top end */

/* Right start */

.right-2pt5 {
  right: 0.625rem;
}

/* Right end */

/* Bottom start */

.bottom-8 {
  bottom: 2rem;
}

/* Bottom end */

/* Opacity Start */

.opacity-60 {
  opacity: 0.6;
}

/* Opacity End */

/* Text Alignment Start */

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

/* Text Alignment End */

/* Cursor Start */

.cursor-pointer {
  cursor: pointer;
}

/* Cursor End */

/* Text Transform Start */
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}
/* Text Transform End */

/* Text Decoration Start */
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.no-underline {
  text-decoration: none;
}
/* Text Decoration End */
